@import "../node_modules/bootstrap/dist/css/bootstrap.css";
@import "../src/variable";
@import "../node_modules/ol/ol.css";
@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Lato:wght@200;400;600&display=swap');
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

/* You can add global styles to this file, and also import other style files */
body{
    margin: 0;
    font-size:12px;
    height:100vh;
    background-color: #f3f3f3;
    font-family: 'Lato';
}

.center {
    position: relative;
    //left: 50%;
    top: 50%;
   // transform: translate(-50%, -50%);
   transform: translate(0,-50%);
   // padding: 2rem;
}

.form-group {
    margin-bottom: 1rem;
  }

  @include media-breakpoint-up(xl) {
    .w-xl-90 {
      width: 90% !important;
    }
  }

  @include media-breakpoint-up(md) {
    .w-md-35 {
      width: 35% !important;
    }
  }
  @include media-breakpoint-up(xl) {
    .w-lg-25 {
      width: 25% !important;
    }
  }

  @include media-breakpoint-down(md) {
    .w-d-md-100 {
      width: 100% !important;
    }
  }
  
// FOCO Brand Font - Regular
@font-face {
    font-family: 'foco-regular';
    src: url('/assets/fonts/foco-regular-webfont.eot');
    src: url('/assets/fonts/foco-regular-webfont.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/foco-regular-webfont.woff') format('woff'), url('/assets/fonts/foco-regular-webfont.ttf') format('truetype'), /**/
    url('/assets/fonts/foco-regular-webfont.svg#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

// FOCO Brand Font - Bold
@font-face {
    font-family: 'foco-bold';
    src: url('/assets/fonts/foco-bold-webfont.eot');
    src: url('/assets/fonts/foco-bold-webfont.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/foco-bold-webfont.woff') format('woff'), url('/assets/fonts/foco-bold-webfont.ttf') format('truetype'), url('/assets/fonts/foco-bold-webfont.svg#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

// Icomoon Icon Font
@font-face {
    font-family: 'icomoon';
    src: url('/assets/fonts/icomoon.eot');
    src: url('/assets/fonts/icomoon.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/icomoon.woff') format('woff'), url('/assets/fonts/icomoon.ttf') format('truetype'), url('/assets/fonts/icomoon.svg#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

//Lato font
@font-face {
    font-family: 'Lato';
    src: url('/assets/fonts/Lato/Lato-Regular.ttf');
    src: url('/assets/fonts/Lato/Lato-Regular.ttf') format('truetype'), url('/assets/fonts/Lato/Lato-Bold.ttf') format('truetype'), url('/assets/fonts/Lato/Lato-Light.ttf') format('truetype'), url('/assets/fonts/Lato/Lato-Thin.ttf') format('truetype');
    font-weight: normal;
    font-style: normal; 
}

.btn-lime{
    box-shadow: none;
    background-color: $colour-header--blue;
    color:whitesmoke;
    &:hover{
      background-color: whitesmoke;
      color:$colour-header--blue;
      border:1px solid $colour-header--blue;
    }
   
  }

    .btn-teal {
      box-shadow: none;
      background-color: $colour-secondary-blue;
      color: whitesmoke;
  
      &:hover {
        background-color: $colour-secondary-blue;
        border: 1px solid black;
        color: whitesmoke;
        font-weight: bold;
      }
    }
  .form-check-input:checked{
    background-color: $colour-header--blue;
    border-color: $colour-secondary-blue;
  }

  .form-check-input{
    @media(max-width:$screen-md-min) {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  .form-check-label{
    margin-left:0.25rem;
  }
  .form-option:focus{
    background-color: $colour-header--blue;
    border-color: $colour-secondary-blue;
  }

  .form-option:disabled{
    background-color:#E3E3E3;
  }

  .btn-spinner{
    height: 1rem;
    width: 1rem;
  }

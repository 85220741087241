

$app-teal:#1A535C;
$app-lime:#A5E758;
$app-background:#E4E4E4;
$app-light : #f8f8f8;
$app-teal-blue : #348AA7;
$app-title : #222222;


/* header colors */

$colour-header--purple:#503e58;
$colour-header--blue: #37AFC8;
$colour-secondary-blue : #004E70;
$headerHeight : 50px;

// Small screen / tablet
$screen-sm-min:              768px;
$screen-sm:                  $screen-sm-min;

// Medium screen / desktop
$screen-md-min:              960px;
$screen-md:                  $screen-md-min;

// Large screen / wide desktop
$screen-lg:                  1400px;
$screen-lg-min:             990px;

//extra large screens
$screen-xl:                1680px;
$screen-xl-min:            1440px;


$panelShadow : 0px 1px 4px 0px #555555 !default;
$tabBarBorderRadius : 8px;

// Sea blue map background
$mapBackground: #cce5f3;
$headerZIndex : 9400;